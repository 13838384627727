// Actions
const CHANGE_LOADING_STATE = 'changeLoadingState'
const CHANGE_PROJECT_LOADING_STATE = 'changeProjectLoadingState'
const CHANGE_ACTIVE_PROJECT = 'changeActiveProject'
const CHANGE_ACTIVE_BUILDING = 'changeActiveBuilding'
const LOAD_LANDLORD_DETAILS = 'loadLandlordDetails'
const LOAD_PROJECT_AVAILABILITY = 'loadProjectAvailability'
const CHANGE_FILTER_STATE = 'changeFilterState'
const RESET_FILTERS = 'resetFilters'
const RESET_FLOOR_FILTER = 'resetFloorFilter'
const CLEAR_AMENITIES = 'clearAmenities'
const LOAD_TOTAL_AVAILABLE_RESULTS = 'loadTotalAvailableResults'
const CHANGE_CURRENT_SPACE = 'changeCurrentSpace'

// Mutations
const SET_LANDLORD_DETAILS = 'setLandlordDetails'
const SET_LOADING_STATE = 'setLoadingState'
const SET_PROJECT_LOADING_STATE = 'setProjectLoadingState'
const SET_PROJECT_AVAILABILITY = 'setProjectAvailability'
const SET_ACTIVE_PROJECT = 'setActiveProject'
const SET_ACTIVE_BUILDING = 'setActiveBuilding'
const SET_FILTER_STATE = 'setFilterState'
const SET_EMPTY_FILTERS = 'setEmptyFilters'
const SET_TOTAL_AVAILABLE_RESULTS = 'setTotalAvailableResults'
const SET_CURRENT_SPACE = 'setCurrentState'

// Namespace
const NAMESPACE = 'project'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    CHANGE_LOADING_STATE,
    CHANGE_PROJECT_LOADING_STATE,
    CHANGE_ACTIVE_PROJECT,
    CHANGE_ACTIVE_BUILDING,
    LOAD_LANDLORD_DETAILS,
    LOAD_PROJECT_AVAILABILITY,
    CHANGE_FILTER_STATE,
    RESET_FILTERS,
    RESET_FLOOR_FILTER,
    CLEAR_AMENITIES,
    LOAD_TOTAL_AVAILABLE_RESULTS,
    CHANGE_CURRENT_SPACE
  },
  mutation: {
    SET_LANDLORD_DETAILS,
    SET_LOADING_STATE,
    SET_ACTIVE_PROJECT,
    SET_ACTIVE_BUILDING,
    SET_PROJECT_LOADING_STATE,
    SET_PROJECT_AVAILABILITY,
    SET_FILTER_STATE,
    SET_EMPTY_FILTERS,
    SET_TOTAL_AVAILABLE_RESULTS,
    SET_CURRENT_SPACE
  },
  namespace: NAMESPACE,
  withNamespace
}
