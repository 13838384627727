export default function ({ store, route, redirect }) {
  if (route.name !== 'slug') return
  const projects = store.state.base.landlordDetails?.projects || []
  const redirects = projects.map((project) => ({
    from: `/${project.slug}/`,
    to: `/project/${project.slug}/`
  }))
  const redirectObj = redirects.find((r) => r.from === route.path)
  if (!redirectObj) {
    redirect(301, '/')
    return
  }
  redirect(301, redirectObj.to)
}
