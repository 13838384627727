var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],staticClass:"atom-dropdown"},[_c('div',{staticClass:"selected-value",class:{ active: _vm.isMenuOpen },on:{"click":_vm.toggleMenu}},[_c('p',{staticClass:"value"},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"location"}}),_vm._v("\n      "+_vm._s(_vm.getCurrentDropdownValue())+"\n    ")],1)]),_vm._v(" "),(_vm.isMenuOpen)?_c('div',{staticClass:"menu-options"},[(_vm.projects.length)?_c('div',{staticClass:"row row-elements"},[_c('div',{staticClass:"col col-6 left-sidebar"},[_c('p',[_vm._v("Project")]),_vm._v(" "),_vm._l((_vm.projects),function(project,index){return _c('NuxtLink',{key:`project-${index}`,staticClass:"option",class:{ active: _vm.activeProject === project.slug },attrs:{"tag":"a","to":{
            path: `/project/${project.slug}`
          }},nativeOn:{"mouseover":function($event){return _vm.filterBuildingsByProject(project.id)},"click":function($event){return _vm.handleChangeActiveBuilding(null)}}},[_vm._v("\n          "+_vm._s(project.name)+"\n        ")])})],2),_vm._v(" "),_c('div',{staticClass:"col col-6 right-sidebar"},[_c('p',[_vm._v("Building")]),_vm._v(" "),_vm._l((_vm.filteredBuildings),function(building,index){return _c('NuxtLink',{key:`building-${index}`,staticClass:"option",class:{ active: _vm.activeBuilding === building.slug },attrs:{"tag":"a","to":{
            path: `/project/${_vm.getProjectOfBuilding(building.project_id)}`,
            query: {
              building: building.slug
            }
          }},nativeOn:{"click":function($event){return _vm.handleChangeActiveBuilding(building)}}},[_vm._v("\n          "+_vm._s(building.name)+"\n        ")])})],2)]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }