import { ProjectState } from '~/store/project/-types'

export default {
  getActiveProjectData(state: ProjectState) {
    return state.projects.find((item: any) => item.slug === state.activeProject)
  },

  getActiveBuildingData(state: ProjectState) {
    if (!state.activeBuilding) return {}
    return state.buildings.find((item: any) => item.slug === state.activeBuilding)
  }
}
